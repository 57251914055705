@media (max-width: 600px){
    .leftCn{
        margin-top: 10px !important;
    }
}
/* HomeR.css */

.hakx {
    text-shadow: 0 0 12px rgba(0, 123, 255, 0.7);
                 
                 
                 
  }
  
@media (max-width: 1010px){
    .hakx{
        font-size: 56px !important;
    }

    .inter{
        font-size: 18px !important;
    }

    .inter1{
      font-size: 28px !important;
  }
}

@media (max-width: 389px){
    .hakx{
        font-size: 52px !important;
    }

    .inter{
        font-size: 17px !important;
    }

    .inter1{
      font-size: 23px !important;
  }

    
}


@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');


/* .leftCn{
    top:400px !important;
} */

/* .leftCn, .rightCn {
    padding-left: 0; 
    padding-right: 0; 
  } */
/* 
  @media (min-width: 1100px){
    img{
        left: -40%;

    }
  }   */


  .custom-button1 {
    background-color: #1e99f1; 
    border-color:  #1e99f1 ;
    border-width: 3px;
    border-radius: 50px;
    font-size: 18px;
    font-weight: bolder;
    color: black;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin-top: 10px;
    transition-duration: 0.4s;
    cursor: pointer;
  }
  
  .custom-button1:hover {
    font-weight: bolder;
    background-color: rgba(46, 46, 46, 0.5);
    color: white;
    border: 3px solid #1e99f1;
  }

  @media (min-width: 700px) and (max-width: 1000px) {
    .leftCn{
      margin-top: 80px !important;
    }

    .img2{
      width: 250% !important;
    }
  }
  
   .custom-button1 {
    width: 200px;
    white-space: nowrap; /* Prevent text wrapping inside the button */
    position: relative;
    z-index: 10; /* Ensure buttons are on top of other elements */
  }

  .custom-button3 {
    border-radius: 3px;
    display: flex;
    align-items: center;
  }
  
  .custom-button3 .upload-icon {
    margin-right: 8px; /* Adjust spacing as needed */
  }
  .glass-container {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    backdrop-filter: blur(10px);
    padding: 20px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .custom-button3 {
    border-radius: 3px;
    display: flex;
    align-items: center;
    margin: 10px 0;
  }
  
  /* .custom-button3 .upload-icon {
    margin-left: -28px; 
  } */
  
  .dropdown-container {
    position: relative;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 1000;
    display: none;
  }
  
  .dropdown-container:hover .dropdown-menu {
    display: block;
  }
  @media (max-width: 600px){
    /* .glass-container{
      width: 60% !important;
      left:20% !important;
    } */
  }